<template>
  <v-app id="inspire" style="background-color: #ffffff">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <br>
            <v-row justify="center" align="center">
              <v-img
                src="@/assets/logo.png"
                max-width="120px"
                max-height="120px"
              />
              <h1 style="font-weight: 800; font-size: 36px" class="mt-8">
                Police Rider
              </h1>
            </v-row>
            <v-card class="elevation-12 mt-12">
              <v-toolbar color="#833133" style="color: white">
                <h3 v-if="!OTPpass" style="color: white">
                  เข้าสู่ระบบด้วยหมายเลขโทรศัพท์
                </h3>
                <h3 v-else style="color: white">
                  กรุณากรอกรหัส OTP ที่ท่านได้รับทาง sms
                </h3>
              </v-toolbar>
              <v-card-text>
                <v-form
                  v-if="!OTPpass"
                  ref="loginfrom"
                  v-model="loginfrom"
                  :lazy-validation="lazy"
                >
                  <v-text-field
                    placeholder="หมายเลขโทรศัพท์"
                    type="number"
                    outlined
                    v-model="phone"
                    counter="10"
                    :rules="rules.empty"
                    @keydown.enter.prevent="login()"
                  ></v-text-field>
                  <v-btn
                    v-if="!clicked"
                    color="#833133"
                    style="color: white"
                    class="font-weight-light"
                    @click="login()"
                    rounded
                    block
                    >ขอรหัส OTP</v-btn
                  >
                  <!-- <v-btn
                    v-if="!clicked"
                    color="#833133"
                    style="color: white"
                    class="font-weight-light"
                    @click="login()"
                    rounded
                    block
                    >เข้าสู่ระบบ</v-btn
                  > -->
                  <v-btn
                    v-else
                    disabled
                    color="#833133"
                    style="color: white"
                    class="font-weight-light"
                    rounded
                    block
                    >ขอรหัส OTP</v-btn
                  >
                </v-form>
                <v-form
                  v-else
                  ref="loginfrom"
                  v-model="loginfrom"
                  :lazy-validation="lazy"
                >
                  <v-text-field
                    placeholder="กรุณากรอก OTP ที่ท่านได้รับทาง sms"
                    type="number"
                    outlined
                    v-model="otp_code"
                    counter="6"
                    :rules="rules.empty"
                    @keydown.enter.prevent="verifyOTP()"
                  ></v-text-field>
                  <v-btn
                    color="#833133"
                    style="color: white"
                    class="font-weight-light"
                    @click="verifyOTP()"
                    rounded
                    block
                    >ยืนยันรหัส OTP</v-btn
                  >
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <!-- <v-btn
                  color="#833133"
                  style="color: white"
                  class="font-weight-light"
                  @click="login()"
                  >Login</v-btnπ
                > -->
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import axios from "axios";
import { Encode, Decode } from "@/services";
export default {
  data() {
    return {
      lazy: "",
      loginfrom: "",
      rules: {
        empty: [(v) => !!v || "กรุณากรอกข้อมูล"],
        counter: (value) => value.length <= 10 || "Max 10 characters",
      },
      // username: "adminadmin",
      // password: "654321",
      username: "",
      password: "",
      clicked: false,
      OTPpass: false,
      // api_key: "38cee89e331ce811052b855dd8490834",
      api_key: "b31fb4cb0403b694565b0279a8aa3983",
      // secret_key: "mwkpqtKVnytz1zfQ",
      secret_key: "8U13tP7rVfQrdwln",
      project_key: "4Zfi315cuX",
      phone: "",
      token: "",
      otp_code: "",
      headersdrugclues: [
        // { text: "ลำดับ", value: "countNo", align: "center",width: '50px' },
        { text: "บช./ภาค ", value: "area", align: "center" , width: '100px' },
        { text: "หมายเลขโทรศัพท์", value: "tel", align: "center", width: '200px' },
        { text: "ยศ ชื่อ สกุล", value: "name", align: "center", width: '300px' },
        { text: "ตำแหน่ง", value: "position", align: "center", width: '200px' },
      ],
      contactDetails: [
        {
          countNo: "1",
          area: "น.",
          name: "ส.ต.อ.หญิง ภาวินี คารวะวงศ์",
          position: "ผบ.หมู่ฯ ปฏิบัติราชการ ศอ.ปส.บช.น.",
          tel: "091-4699195",
        },
        {
          countNo: "2",
          area: "ภ.1",
          name: "ร.ต.อ.ชูสกุล บุญหมื่นไวย์",
          position: "รอง สว.ฯ ปฏิบัติราชการ ศอ.ปส.ภ.1",
          tel: "098-8264142",
        },
        {
          countNo: "3",
          area: "ภ.2",
          name: "ร.ต.อ.หญิง พรพรรณ์ เพชรฉิมกัน",
          position: "รอง สว.ฯ ปฏิบัติราชการ ศอ.ปส.ภ.2",
          tel: "087-8492010",
        },
        {
          countNo: "4",
          area: "ภ.3",
          name: "ด.ต.หญิง อำนวยพร ศิริมณี",
          position: "ผบ.หมู่ฯ ปฏิบัติราชการ ศอ.ปส.ภ.3",
          tel: "098-1485644",
        },
        {
          countNo: "5",
          area: "ภ.4",
          name: "พ.ต.ท.รชต มณีวงศ์",
          position: "รอง ผกก.ฯ ปฏิบัติราชการ ศอ.ปส.ภ.4",
          tel: "093-0535522",
        },
        {
          countNo: "6",
          area: "ภ.5",
          name: "ส.ต.ท.ภาวิศ เครือนาค",
          position: "ผบ.หมู่ฯ ปฏิบัติราชการ ศอ.ปส.ภ.5",
          tel: "097-9539987",
        },
        {
          countNo: "7",
          area: "ภ.6",
          name: "ด.ต.ธวัฒ เวียงสาม",
          position: "ผบ.หมู่ฯ ปฏิบัติราชการ ศอ.ปส.ภ.6",
          tel: "086-2071515",
        },
        {
          countNo: "8",
          area: "ภ.7",
          name: "ส.ต.ต.ธีรวัฒน์ จีนบุบผา",
          position: "ผบ.หมู่ฯ ปฏิบัติราชการ ศอ.ปส.ภ.7",
          tel: "095-0028259",
        },
        {
          countNo: "9",
          area: "ภ.8",
          name: "พ.ต.ต.มาโนช คงคาชัย",
          position: "ผบ.หมู่ฯ ปฏิบัติราชการ ศอ.ปส.ภ.8",
          tel: "088-7325298",
        },
        {
          countNo: "10",
          area: "ภ.9",
          name: "ด.ต.อุเทน ลอย​ลิบ​",
          position: "ผบ.หมู่ฯ ปฏิบัติราชการ ศอ.ปส.ภ.9",
          tel: "083-6560579​",
        },
      ],
    };
  },
  created() {
    if (Object.prototype.hasOwnProperty.call(localStorage, "user")) {
      var checklogin = JSON.parse(Decode.decode(localStorage.getItem("user")))
        .name;
      if (checklogin !== "") {
        // console.log(checklogin);
        // this.$router.push("/report");
        this.$router.push("/index");
      }
    }
  },
  methods: {
    async login() {
      if (this.$refs.loginfrom.validate(true)) {
        // console.log("phone", this.phone.length);
        // const login = {
        //   phone: this.phone,
        // };
        // const response = await axios.post(
        //   `${process.env.VUE_APP_API}/user/login`,
        //   login
        // );
        // console.log("login", response.data);

        // if (response.data.response_status === "SUCCESS") {
        //   console.log("login ผ่าน");
        //   this.clicked = true;
        //   localStorage.setItem("_temp", Encode.encode(response.data.data));
        //   // await this.OTP();
          // localStorage.setItem("user", Encode.encode(response.data.data));
          localStorage.setItem("user", Encode.encode("fakedata"));
          this.$router.push("/index");
        // } else if (this.phone.length != 10) {
        //   await this.$swal.fire({
        //     showConfirmButton: false,
        //     timer: 1000,
        //     timerProgressBar: true,
        //     icon: "error",
        //     text: "กรุณากรอกหมายเลขโทรศัพท์ให้ถูกต้อง",
        //   });
        // } else {
        //   await this.$swal.fire({
        //     // showConfirmButton: false,
        //     // timer: 5000,
        //     // timerProgressBar: true,
        //     icon: "info",
        //     title: "ไม่พบเบอร์โทรศัพท์ของท่านในระบบ",
        //     html:
        //       "กรุณาเข้าใช้งานด้วยเบอร์โทรศัพท์ของเจ้าหน้าที่ที่แจ้งไว้",
        //     // footer: '<a href="">หมายลขโทรศัพท์ที่จะแจ้งไว้</a>',
        //   });
        // }
      }
    },
    async OTP() {
      // let formData = new FormData();
      const auth = {
        headers: {
          "Content-Type": "application/json",
          api_key: this.api_key,
          secret_key: this.secret_key,
        },
      };
      const params = {
        project_key: this.project_key,
        phone: this.phone,
      };
      const response = await this.axios.post(
        `https://portal-otp.smsmkt.com/api/otp-send`,
        params,
        auth
      );
      console.log("response", response);
      this.token = response.data.result.token;
      console.log("token", this.token);
      if (response.data.code === "000") {
        this.OTPpass = true;
      } else if (this.phone == "") {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
          icon: "error",
          text: "กรุณากรอกหมายเลขโทรศัพท์",
        });
      } else {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          icon: "error",
          text: "ระบบ OTPขัดข้อง",
        });
      }
    },
    async verifyOTP() {
      const auth = {
        headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          api_key: "38cee89e331ce811052b855dd8490834",
          secret_key: "mwkpqtKVnytz1zfQ",
          // "Access-Control-Allow-Origin": "*"
        },
      };
      const params = {
        token: this.token,
        otp_code: this.otp_code,
      };
      console.log("param", params);
      const response = await this.axios.post(
        `https://portal-otp.smsmkt.com/api/otp-validate`,
        params,
        auth
      );
      console.log("verify otp success", response);
      if (response.data.code === "000") {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          icon: "success",
          text: "เข้าสู่ระบบเสร็จสิ้น",
        });
        var temp = JSON.parse(Decode.decode(localStorage.getItem("_temp")));
        localStorage.setItem("user", Encode.encode(temp));
        this.$router.push("/index");
      } else {
        await this.$swal.fire({
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar: true,
          icon: "error",
          text: "รหัส OTP ของท่านผิด กรุณากรอกใหม่อีกครั้ง",
        });
      }
    },
    resetValidation() {
      this.$refs.loginfrom.resetValidation();
    },
  },
};
</script>
