<template>
  <v-row class="pa-8" style="height: 100%">
    <v-col cols="12" md="9" sm="12">
      <!-- <google-map id="map" ref="Map">
      <google-map-marker
          :key="index"
          v-for="(infoWindow, index) in documents"
          :position="infoWindow.position"
          @click="toggleInfoWindow(infoWindow)"
          :icon="d"
      />
    </google-map> -->
      <v-col cols="12" md="12" sm="12">
        <v-row >
          <v-col cols="3">
                          <!-- <v-btn dense class="ma-4">ดูตารางการเช็คอิน</v-btn> -->
                          <p>
          จำนวนเจ้าหน้าที่ Online:
          <span
            ><b>{{ documents.length }}</b></span
          >
          คน
        </p>
                        </v-col>
                        <v-col cols="6">
                          <!-- <v-btn dense class="ma-4">ดูตารางการเช็คอิน</v-btn> -->
                          <v-btn color="primary" @click="clickShowName()">แสดงชื่อบนแผนที่</v-btn>
                        </v-col>
                      </v-row>
        
      </v-col>
      <v-card height="85vh">
        <gmap-map :center="centerPosition" :zoom="14">
          <gmap-marker
            :key="index"
            v-for="(m, index) in documents"
            :position="m.position"
            :clickable="true"
            @click="clickShowName(m)"
            :icon="
              m.userType == 'USER'
                ? markerOptions_people
                : m.userType == 'POLICE_1'
                ? markerOptions_police_1
                : m.userType == 'POLICE_2'
                ? markerOptions_police_2
                : markerOptions_people
            "
          >
            <gmap-info-window
              @closeclick="showName = false"
              :opened="showName"
              :position="infowindow"
            >
              {{ m.radioCode }}
            </gmap-info-window>
          </gmap-marker>
        </gmap-map>
      </v-card>
    </v-col>
    <v-col cols="12" md="3" sm="12">
      <v-row dense>
        <v-col cols="12" md="12" sm="12">
          <v-row align="center" justify="center">
                        <v-col cols="6">
                         <v-btn color="primary" @click="clickAllCheckin()" dense>
            ดูตารางเช็คอินทั้งหมด
          </v-btn></v-col
                        >
                      </v-row>
          
        </v-col>
        <v-col cols="12" md="12" sm="12">
          
        </v-col>
        <v-col cols="12" md="12" sm="12">
          <v-text-field
            outlined
            dense
            placeholder="ค้นหาเจ้าหน้าที่"
          ></v-text-field>
        </v-col>
        <!-- <v-toolbar>
          <v-btn @click="clickAllCheckin()" dense>
            ดูตารางเช็คอินทั้งหมด
          </v-btn>
        </v-toolbar> -->
        <div id="app">
          <v-app>
            <v-card height="80vh">
              <v-divider></v-divider>

              <v-card-text class="pa-0 ma-0">
                <v-list>
                  <v-col
                    height="85vh"
                    cols="12"
                    md="12"
                    sm="12"
                    v-for="(m, index) in documents"
                    :key="index"
                    class="container-body"
                    @mousewheel="handelScroll"
                  >
                    <v-card
                      outlined
                      style="padding: 10px"
                      v-if="m.position.lat != ''"
                    >
                      <v-row dense>
                        <v-col cols="12" md="4" sm="4">
                          <v-img
                            src="@/assets/police_img.png"
                            v-if="!m.profileImg"
                            height="200"
                            width="200"
                          ></v-img>
                          <v-img
                            :src="m.profileImg"
                            v-if="m.profileImg"
                            height="150"
                            width="150"
                            contain
                          ></v-img>
                        </v-col>
                        <v-col cols="12" md="8" sm="8">
                          <v-col cols="12"
                            ><p style="font-size: 12px">ชื่อ: {{ m.name }}</p>
                            <p style="font-size: 12px">
                              ตำแหน่ง: {{ m.userPosition }}
                            </p>

                            <p style="font-size: 12px">
                              เบอร์โทรศัพท์: {{ m.tel }}
                            </p>
                            <p style="font-size: 12px">
                              รหัสวิทยุ: {{ m.radioCode }}
                            </p></v-col
                          >
                        </v-col>
                      </v-row>
                      <v-row align="center" justify="center">
                        <v-col cols="6">
                          <!-- <v-btn dense class="ma-4">ดูตารางการเช็คอิน</v-btn> -->
                          <v-btn @click="clickShowLocation(m)" dense>
                            ตำแหน่งปัจจุบัน</v-btn
                          >
                        </v-col>
                        <v-col cols="6">
                          <v-btn @click="clickCheckin(m)" dense class="mr-4">
                            ตารางเช็คอิน</v-btn
                          ></v-col
                        >
                      </v-row>
                    </v-card>
                  </v-col>
                </v-list>
              </v-card-text>
            </v-card>
          </v-app>
        </div>
      </v-row>
    </v-col>
  </v-row>
</template>
<style>
.vue-map-container,
.vue-map-container .vue-map {
  width: 100%;
  height: 100%;
}
</style>
<script>
import { Decode, Encode } from "@/services";
import LeftIndex from "@/components/LeftIndex";
import RightIndex from "@/components/RightIndex";
import { db } from "../db";
import * as VueGoogleMaps from "vue2-google-maps";
const mapMarkerPeople = require("@/assets/marker/people.png");
const mapMarkerPolice1 = require("@/assets/marker/police_1.png");
const mapMarkerPolice2 = require("@/assets/marker/police_2.png");
export default {
  components: {
    LeftIndex,
    RightIndex,
  },
  data() {
    return {
      headers: [
        { text: "รูป", value: "profileImg", align: "center" },
        { text: "ชื่อ", value: "name", align: "center" },
        { text: "ตำแหน่ง", value: "userPosition", align: "center" },
        { text: "เบอร์โทรศัพท์", value: "tel", align: "center" },
        { text: "รหัสวิทยุ", value: "radioCode", align: "center" },
        { text: "ตัวเลือก", value: "action", align: "center" },
      ],
      documents: [],
      centerPosition: {
        lat: 13.7324414,
        lng: 100.5436332,
      },
      infoWindowContext: {
        position: {
          lat: 13.748216975919972,
          lng: 100.54349785367529,
        },
      },
      infowindow: { lat: 10, lng: 10.0 },
      markerOptions_police_1: {
        url: mapMarkerPolice1,
        size: { width: 50, height: 50, f: "px", b: "px" },
        scaledSize: { width: 50, height: 50, f: "px", b: "px" },
      },
      markerOptions_police_2: {
        url: mapMarkerPolice2,
        size: { width: 50, height: 50, f: "px", b: "px" },
        scaledSize: { width: 50, height: 50, f: "px", b: "px" },
      },
      markerOptions_people: {
        url: mapMarkerPeople,
        size: { width: 50, height: 50, f: "px", b: "px" },
        scaledSize: { width: 50, height: 50, f: "px", b: "px" },
      },
      showName: true,
    };
  },
  created() {
    // db.collection("locations")
    //   .get()
    //   .then((querySnapshot) => {
    //     const documentsData = querySnapshot.docs.map((doc) => doc.data());
    //     // do something with documents
    //     console.log(documentsData);
    //   });
    // console.log(this.documents);
  },
  methods: {
    handelScroll() {
      let scrollDiv = document.getElementsByClassName("mynav");
      console.log(scrollDiv);
      if (window.scrollY < 100) {
        console.log(window.scrollY, scrollDiv);
        scrollDiv[0].classList.add("updateClass");
      } else {
        scrollDiv[0].classList.remove("updateClass");
      }
    },
    clickAllCheckin() {
      window.open("checkinAll");
    },
    clickCheckin(val) {
      localStorage.setItem("checkinData", Encode.encode(val));
      // this.$router.push("viewProduct");
      window.open("checkin");
    },
    clickShowName() {
      // console.log();
      if (this.showName) {
        this.showName = false;
      } else {
        this.showName = true;
      }
    },
    clickShowLocation(m) {
      console.log(m);
      if (m.position) {
        this.centerPosition.lat = m.position.lat;
        this.centerPosition.lng = m.position.lng;
      }
    },
    toggleInfoWindow(context) {
      this.infoWIndowContext = context;
      this.showInfo = true;
    },
    infoClicked(context) {
      console.log(context);
    },
  },
  firestore: {
    documents: db
      .collection("locations")
      .where("position.lat", "!=", "")
      .where("station", "==", "สน.ลุมพินี"),
  },
};
</script>
<style scoped>
.updateClass {
  display: none;
}
html {
  overflow: hidden !important;
}

.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
</style>
