<template>
  <div id="app">
    <v-app id="inspire">
      <v-navigation-drawer v-model="drawer" app>
        <v-list height="180" class="mt-3">
          <a-row type="flex" justify="center" align="middle">
            <a-col :span="24" class="centerdiv">
              <v-avatar size="120">
                <!-- <img src="../assets/logo_survey.png" /> -->
              </v-avatar>
            </a-col>
            <a-col :span="24" class="centerdiv">
              <!-- <p style="font-weight: 400; font-size: 18px">{{ username }}</p> -->
            </a-col>
          </a-row>
        </v-list>
        <v-divider></v-divider>
        <a-menu mode="inline" @click="handleClick">
          <!-- <a-menu-item
            v-for="item in Navigator"
            :key="item.key"
            @click="ChangePage(item)"
          >
            <span class="pl-2">{{ item.text }}</span>
          </a-menu-item> -->
          <a-sub-menu
            @titleClick="titleClick"
            v-for="item in Navigator"
            :key="item.key"
          >
            <span slot="title">
              <span>{{ item.headerName }}</span>
            </span>
            <a-menu-item
              v-for="menuList in item.menuItem"
              :key="menuList.key"
              @click="ChangePage(menuList)"
            >
              {{ menuList.text }}
            </a-menu-item>
            <!-- <a-menu-item key="2"> Option 2 </a-menu-item> -->
          </a-sub-menu>

          <!-- <a-sub-menu key="sub2" @titleClick="titleClick">
            <span slot="title">
              <span>ค้นหาข้อมูล</span>
            </span>
            <a-menu-item key="1"> Option 3 </a-menu-item>
            <a-menu-item key="2"> Option 4 </a-menu-item>
          </a-sub-menu> -->
        </a-menu>
      </v-navigation-drawer>
      <v-app-bar app color="#833133">
        <!-- <v-app-bar-nav-icon
          @click.stop="drawer = !drawer"
          style="color: white"
        ></v-app-bar-nav-icon> -->
        <v-img
          class="mx-2"
          src="../assets/logo.png"
          max-height="40"
          max-width="40"
          contain
        ></v-img>
        <v-toolbar-title style="color: #ffff;" class="ml-2">
    Police Rider
  </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="white" text @click="Logout()">ออกจากระบบ</v-btn>
      </v-app-bar>
      <v-main class="div_overflow">
        <router-view></router-view>
      </v-main>
    </v-app>
  </div>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      drawer: false,
      Header: "",
      // picture: "",
      username: "",
      NavigatorAdmin: [
        {
          headerName: "ภาพรวมสถิติและคะแนน",
          menuItem: [
            {
              key: "1",
              text: "สรุปสถิติคะแนนแบบสอบถาม",
              path: "/report",
              name: "report",
            },
            {
              key: "2",
              text: "สรุปจำนวนผู้ตอบแบบสอบถาม",
              path: "/report2",
              name: "report2",
            },
            {
              key: "7",
              text: "สรุปจำนวนการ x-ray",
              path: "/ReportXray",
              name: "ReportXray",
            },
            {
              key: "10",
              text: "สรุปจำนวนเบาะแสยาเสพติด",
              path: "/ReportDrugClues",
              name: "ReportDrugClues",
            },
            // {
            //   key: "12",
            //   text: "สรุปผลโครงการชุมชนยั่งยืน",
            //   path: "/ManageForm4",
            //   name: "ManageForm4",
            // },
          ],
        },
        {
          headerName: "ค้นหา/แก้ไข ข้อมูล",
          menuItem: [
            {
              key: "6",
              text: "ค้นหารายชื่อการ X-ray",
              path: "/ManageForm3",
              name: "ManageFormSheet3",
            },
            {
              key: "9",
              text: "ค้นหาข้อมูลเบาะแสยาเสพติด",
              path: "/ManageDrugClues",
              name: "ManageDrugClues",
            },
            {
              key: "14",
              text: "ค้นหารายชื่อคณะกรรมการคุ้ม",
              path: "/ManageKum",
              name: "ManageKum",
            },
          ],
        },
        {
          headerName: "ระบบบันทึกข้อมูล",
          menuItem: [
            {
              key: "5",
              text: "บันทึกข้อมูลการ X-Ray",
              path: "/Form3",
              name: "FormSheet3",
            },
            {
              key: "8",
              text: "บันทึกข้อมูลการแจ้งเบาะแส",
              path: "/Form2Police",
              name: "FormSheet2",
            },
            {
              key: "15",
              text: "บันทึกข้อมูลคณะกรรมการคุ้ม",
              path: "/addKum",
              name: "addKum",
            },
            {
              key: "13",
              text: "บันทึกจำนวนประชากร/เป้าหมาย",
              path: "/Form4",
              name: "FormSheet4",
            },
          ],
        },
        {
          headerName: "จัดการระบบ",
          menuItem: [
            {
              key: "4",
              text: "จัดการQRcode",
              path: "/ManageQRcode",
              name: "ManageQRcode",
            },
            {
              key: "3",
              text: "จัดการแบบสอบถาม",
              path: "/ManageSurvey",
              name: "ManageSurvey",
            },
            {
              key: "11",
              text: "จัดการข้อมูลผู้ใช้งาน",
              path: "/ManageUser",
              name: "ManageUser",
            },
          ],
        },
      ],
      NavigatorDefault: [
        {
          headerName: "ภาพรวมสถิติและคะแนน",
          menuItem: [
            {
              key: "1",
              text: "สรุปสถิติคะแนนแบบสอบถาม",
              path: "/report",
              name: "report",
            },
            {
              key: "2",
              text: "สรุปจำนวนผู้ตอบแบบสอบถาม",
              path: "/report2",
              name: "report2",
            },
            {
              key: "7",
              text: "สรุปจำนวนการ x-ray",
              path: "/ReportXray",
              name: "ReportXray",
            },
            {
              key: "10",
              text: "สรุปจำนวนเบาะแสยาเสพติด",
              path: "/ReportDrugClues",
              name: "ReportDrugClues",
            },
            // {
            //   key: "12",
            //   text: "สรุปผลโครงการชุมชนยั่งยืน",
            //   path: "/ManageForm4",
            //   name: "ManageForm4",
            // },
          ],
        },
        {
          headerName: "ค้นหา/แก้ไข ข้อมูล",
          menuItem: [
            {
              key: "6",
              text: "ค้นหารายชื่อการ X-ray",
              path: "/ManageForm3",
              name: "ManageFormSheet3",
            },
            {
              key: "9",
              text: "ค้นหาข้อมูลเบาะแสยาเสพติด",
              path: "/ManageDrugClues",
              name: "ManageDrugClues",
            },
            {
              key: "14",
              text: "ค้นหารายชื่อคณะกรรมการคุ้ม",
              path: "/ManageKum",
              name: "ManageKum",
            },
          ],
        },
        {
          headerName: "ระบบบันทึกข้อมูล",
          menuItem: [
            {
              key: "5",
              text: "บันทึกข้อมูลการ X-Ray",
              path: "/Form3",
              name: "FormSheet3",
            },
            {
              key: "8",
              text: "บันทึกข้อมูลการแจ้งเบาะแส",
              path: "/Form2Police",
              name: "FormSheet2",
            },
            // {
            //   key: "15",
            //   text: "บันทึกข้อมูลคณะกรรมการคุ้ม",
            //   path: "/addKum",
            //   name: "addKum",
            // },
          ],
        },
      ],
      NavigatorOperation: [
        {
          headerName: "ภาพรวมสถิติและคะแนน",
          menuItem: [
            {
              key: "1",
              text: "สรุปสถิติคะแนนแบบสอบถาม",
              path: "/report",
              name: "report",
            },
            {
              key: "2",
              text: "สรุปจำนวนผู้ตอบแบบสอบถาม",
              path: "/report2",
              name: "report2",
            },
            {
              key: "7",
              text: "สรุปจำนวนการ x-ray",
              path: "/ReportXray",
              name: "ReportXray",
            },
            {
              key: "10",
              text: "สรุปจำนวนเบาะแสยาเสพติด",
              path: "/ReportDrugClues",
              name: "ReportDrugClues",
            },
            // {
            //   key: "12",
            //   text: "สรุปผลโครงการชุมชนยั่งยืน",
            //   path: "/ManageForm4",
            //   name: "ManageForm4",
            // },
          ],
        },
        {
          headerName: "ค้นหา/แก้ไข ข้อมูล",
          menuItem: [
            {
              key: "6",
              text: "ค้นหารายชื่อการ X-ray",
              path: "/ManageForm3",
              name: "ManageFormSheet3",
            },
            {
              key: "9",
              text: "ค้นหาข้อมูลเบาะแสยาเสพติด",
              path: "/ManageDrugClues",
              name: "ManageDrugClues",
            },
            {
              key: "14",
              text: "ค้นหารายชื่อคณะกรรมการคุ้ม",
              path: "/ManageKum",
              name: "ManageKum",
            },
          ],
        },
        {
          headerName: "ระบบบันทึกข้อมูล",
          menuItem: [
            {
              key: "5",
              text: "บันทึกข้อมูลการ X-Ray",
              path: "/Form3",
              name: "FormSheet3",
            },
            {
              key: "8",
              text: "บันทึกข้อมูลการแจ้งเบาะแส",
              path: "/Form2Police",
              name: "FormSheet2",
            },
            {
              key: "15",
              text: "บันทึกข้อมูลคณะกรรมการคุ้ม",
              path: "/addKum",
              name: "addKum",
            },
            {
              key: "13",
              text: "บันทึกจำนวนประชากร/เป้าหมาย",
              path: "/Form4",
              name: "FormSheet4",
            },
            // {
            //   key: "13",
            //   text: "บันทึกผลโครงการชุมชนยั่งยืน",
            //   path: "/Form4",
            //   name: "FormSheet4",
            // },
          ],
        },
      ],
      Navigator: [
        // {
        //   headerName: "หัวข้อแรก",
        //   menuItem: [
        //     {
        //       key: "99",
        //       text: "หน้าแรก",
        //       path: "/index",
        //       name: "index",
        //     },
        //   ],
        // },
      ],
    };
  },

  created() {
    // this.Navigator = this.NavigatorDefault;
    var checkuser = JSON.parse(Decode.decode(localStorage.getItem("user")));
    console.log("user", checkuser);
    this.username = checkuser.name;
    console.log("username", this.username);
    // if (checkuser.userType === "99") {
    //   this.Navigator = this.NavigatorAdmin;
    // } else if (checkuser.userType == "ADMIN") {
    //   this.Navigator = this.NavigatorAdmin;
    // } else if (checkuser.userType === "4") {
    //   this.Navigator = this.NavigatorOperation;
    //   // this.Header = "Manage ผู้ให้บริการ";
    //   // this.$router.push({ path: "/ManageService" }).catch(() => {});
    // } else {
    //   this.Navigator = this.NavigatorDefault;
    // }
    this.CheckHeader();
  },
  methods: {
    openKeys(val) {
      console.log("openKeys", val);
    },
    handleClick(e) {
      console.log("click", e);
    },
    titleClick(e) {
      console.log("titleClick", e);
    },
    async ChangePage(val) {
      // // console.log('เข้า ChangePage', val)
      // // console.log(val, this.$router.currentRoute)
      if (val.name !== this.$router.currentRoute.name) {
        this.$router.push({ path: val.path }).catch(() => {});
      }
    },
    CheckHeader(val) {
      this.Header = val;
    },
    Logout() {
      localStorage.removeItem("user");
      window.location.reload();
    },
  },
};
</script>
<style scoped>
.centerdiv {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.div_overflow {
  overflow: auto;
  width: 100%;
  height: 89vh;
}
</style>
<style>
.v-application ul,
.v-application ol {
  padding: 0px 0px !important;
}
</style>
