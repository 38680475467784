<template>
  <v-app style="background-color: #ffffff">
   
    <router-view />
  </v-app>
</template>


<script>
export default {
  methods: {
     gotoMainSurvey() {
      this.$router.push("survey");
    },
    gotoSurvey() {
      this.$router.push("survey1");
    },
    gotoSurvey2() {
      this.$router.push("survey3");
    },
    gotoTable() {
      this.$router.push("table");
    },
    gotoReport() {
      this.$router.push("report");
    },
  },
};
//  <div>
//       <v-tabs>
//         <!-- <v-tab @click="gotoSurvey()">แบบสอบถาม</v-tab> -->
//         <v-tab @click="gotoSurvey2()">แบบประเมินโครงการอบรม</v-tab>
//         <v-tab @click="gotoTable()">ติดตามผู้เสพสารเสพติด</v-tab>
//         <v-tab @click="gotoReport()">ภาพรวม</v-tab>
//       </v-tabs>
//     </div>
</script>
<style>
@import "./assets/styles.css";
</style>
