import Vue from 'vue'
import VueRouter from 'vue-router'

import Homepage from '@/views/Homepage.vue'
import index from '@/views/index.vue'

import login from '../views/Login.vue'


Vue.use(VueRouter)

const routes = [{

    path: '/',
    name: 'login',
    component: login
},
// {

//     path: '/index',
//     name: 'index',
//     component: index
// },

{
    path: '/Home',
    component: Homepage,
    children: [{
        path: '/index',
        name: 'index',
        component: () =>
            import('@/views/index')
    },
    {
        path: '/ManageUser',
        name: 'ManageUser',
        component: () =>
            import('@/views/ManageUser')
    },
    {
        path: '/viewUser',
        name: 'viewUser',
        component: () =>
            import('@/components/User/viewUser.vue')
    },
    {
        path: '/CreateUser',
        name: 'CreateUser',
        component: () =>
            import('@/components/User/CreateUser.vue')
    },
    {
        path: '/EditUser',
        name: 'EditUser',
        component: () =>
            import('@/components/User/EditUser.vue')
    },
    {
        path: '/Checkin',
        name: 'Checkin',
        component: () =>
            import('@/views/Checkin.vue')
    },
    {
        path: '/CheckinAll',
        name: 'CheckinAll',
        component: () =>
            import('@/views/CheckinAll.vue')
    },
    ]
}
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router